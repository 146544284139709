<template>
    <div class="BetNotice allh flex content-center items-center absolute position-center zindex3">
        <div v-show="BetState === 0 || BetState === 1" :class="{'slideDown':BetState === 0 || BetState === 1}"
             class="isbet absolute">
            <img v-show="BetState === 0"
                 :src="'./img/Common/tishi_xzcg' + (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
            <img v-show="BetState === 1"
                 :src="'./img/Common/tishi_shibai' + (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
        </div>
        <div v-show="BetState === 2 || BetState === 3" :class="{'slideDown':BetState === 2 || BetState === 3}"
             class="tableState overflow-hidden absolute">
            <img v-show="BetState === 2" :src="'./img/Common/tishi_ksxz' + (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
            <img v-show="BetState === 3" :src="'./img/Common/tishi_tzxz' + (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
        </div>
        <div v-show="BetState === 4" :class="{'slideDown':BetState === 4}" class="Profit absolute">
            <!--      <img src="../../../public/img/Common/tishi_wxz.png">-->
            <div class="flex items-center">
                <img :src="'./img/Common/tishi_bjyl' + (LangeuageAgent === 'CH' ? '': LangeuageAgent) +'.png'">
                <span v-if="Profit >= 0" class="font36">+{{Profit}}</span>
                <span v-if="Profit < 0" class="font36 colorRed">{{Profit}}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';

    export default {
        name: 'BetNotice',
        props: ['BetState', 'Profit'],
        computed: {
            ...mapState(['LangeuageAgent'])
        }
    }
</script>

<style lang="less">
    .BetNotice {
        pointer-events: none;
        width: 10px;

        > div {
            left: 50%;
            transform: translate(-50%, 0);
        }

        .isbet {
            z-index: 2;
            padding: .1rem .3rem;
            background-color: rgba(68, 68, 68, .6);
            border-radius: 0.06rem;
            top: .25rem;

            img {
                height: .25rem;
            }
        }

        .tableState {
            z-index: 2;
            height: .5rem;
            top: .75rem;

            img {
                width: 2.95rem;
                height: .5rem;
            }
        }

        .Profit {
            z-index: 2;

            img {
                height: .5rem;
            }

            span:not(.colorRed) {
                color: #FDFF00;
            }
        }
    }
</style>
